<script>
  export let title
  export let bio
  export let logo

  function getInitials(name) {
    if (!name || typeof name !== 'string') return 'WA'
    return name
      .split(' ')
      .map(str => str[0])
      .join('')
      .substring(0, 2)
      .toUpperCase()
  }
</script>

<style>
  .bio {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #f4f4f4;
    padding-bottom: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .dot {
    height: 80px;
    width: 80px;
    background-color: #ccc;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 1.4rem;
  }
  p {
    text-align: center;
    font-size: 15px;
    margin: 0;
  }
  .card {
    padding: 0.5rem;
  }
  h2 {
    text-align: center;
    word-break: break-all;
    hyphens: auto;
  }
  img {
    border-radius: 50%;
  }
</style>

<div class="bio">

  {#if logo}
    <img
      src={'https://cdn.wa.link/' + logo}
      alt="thumbnail"
      width="80"
      height="80" />
  {:else}
    <div class="dot">{getInitials(title)}</div>
  {/if}
  <h2>{title}</h2>
  <div class="card">
    <p>{bio}</p>
  </div>
</div>
