<script>
  import WhatsApp from './WhatsApp.svelte'
  export let label
  export let link
</script>

<style>
  .btn {
    display: block;
    margin: 1.3rem 0;
    padding: 16px;
    border-radius: 38px;
    text-align: center;
    text-decoration: none;
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px;
    /* box-shadow: 0px 30px 60px rgba(0, 0, 0, 0.2); */
    /* font-weight: 800; */
    background-color: rgb(0, 182, 108);
    color: rgb(255, 255, 255);
    transition: 0.2s;
  }
  .btn:hover {
    background-color: rgb(84, 84, 84);
    box-shadow: rgba(0, 0, 0, 0.5) 0px 1px 4px;
  }
  .btn span {
    padding-bottom: 2px;
  }
</style>

<a class="btn" href={`https://wa.link/${link}`}>
  <WhatsApp />
  <span>{label}</span>
</a>
