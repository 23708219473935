<script>
  import { ExternalLinkIcon } from 'svelte-feather-icons'
  export let website
  export let lang
  export let privacy
  export let title
</script>

<style>
  .info-container {
    padding: 1rem 0;
    margin-top: 1.5rem;
    margin-bottom: 1rem;
    text-align: center;
    text-align: center;
    border-top: 1px solid #f4f4f4;
    word-break: break-all;
    hyphens: auto;
  }
  a {
    text-align: center;
    word-break: break-all;
    hyphens: auto;
  }
  .privacy {
    margin-bottom: 1rem;
    word-break: normal;
  }
  .link {
    text-decoration: underline;
  }
</style>

<div class="info-container">
  {#if privacy}
    <div class="privacy">
      <small>
        {lang === 'es' ? 'Al contactar estás aceptando la' : 'By contacting you accept the'}
        <a
          class="link"
          target="_blank"
          rel="noopener noreferrer"
          href={/^(https?:|)\/\//.test(privacy) ? privacy : '//' + privacy}>
          {lang === 'es' ? 'política de privacidad' : 'privacy policy'}
        </a>
        {' '} {lang === 'es' ? 'de' : 'of'} {title}
      </small>
    </div>
  {/if}
  {#if website}
    <div>
      <small>{lang === 'es' ? 'Más Información' : 'More info'}:</small>
    </div>
    <a href={/^(https?:|)\/\//.test(website) ? website : 'http://' + website}>
      {website.replace(/^(https?:|)\/\//, '')}
    </a>
    <ExternalLinkIcon size="16" />
  {/if}
</div>
