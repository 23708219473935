<script>
  import { onMount } from 'svelte'
  import Button from './Button.svelte'
  import Bio from './Bio.svelte'
  import Info from './Info.svelte'
  import Loading from './Loading.svelte'

  let title
  let bio
  let links = []
  let isLoading = true
  let error = false
  let website = ''
  let logo = ''
  let privacy = ''
  let path = ''
  let lang = 'en'

  onMount(async () => {
    path = window.location.pathname.replace('/', '')
    const browserLang = window.navigator.language.slice(0, 2)
    lang = browserLang === 'es' ? 'es' : 'en'
    if (!path) {
      return (window.location.href =
        lang === 'es'
          ? 'https://crear.wa.link/premium'
          : 'https://create.wa.link/premium')
    }
    try {
      const res = await fetch(`https://multilink.wa.link/${path}`)
      if (res.ok) {
        const response = await res.json()
        title = response.title || ''
        bio = response.bio || ''
        links = response.links || []
        website = response.website
        logo = response.logo || ''
        privacy = response.privacy || ''
        document.title = title + ' | Walink'
        isLoading = false
      } else {
        window.location.href = 'https://oops.wa.link/' + path
      }
    } catch (e) {
      console.log(e.message)
      error = true
    }
  })
</script>

<style>
  main {
    padding-bottom: 1.4rem;
    padding-top: 2.5rem;
    padding-right: 1rem;
    padding-left: 1rem;
    max-width: 550px;
    margin: 0 auto;
  }

  footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 1.6rem;
    background: linear-gradient(-45deg, #23a6d5, #23d5ab);
    text-align: center;
  }
  .page-container {
    position: relative;
    min-height: 100vh;
  }

  footer small {
    color: #fff;
    font-size: 12px;
  }
</style>

<Loading {isLoading} {error} {lang}>
  <div class="page-container">
    <main>
      <Bio {title} {bio} {logo} />
      {#each links as button}
        <Button link={button.link} label={button.label} />
      {/each}
      <Info {website} {lang} {privacy} {title} />
    </main>
    <footer>
      <small>
        Powered by
        <a href="https://create.wa.link/premium" target="_blank">Walink</a>
      </small>
    </footer>
  </div>
</Loading>
